import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/simple-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "/lesson-plans/"
      }}>{`»`}{` Back to Lesson Plans`}</a></p>
    <p>{`Make at least three substantive posts for this lesson's threads as follows:`}</p>
    <ul>
      <li parentName="ul">{`Post one answer in 0.1.`}</li>
      <li parentName="ul">{`Post one answer in 0.2.`}</li>
      <li parentName="ul">{`Post at least one additional reply to another student's post in either thread.`}</li>
    </ul>
    <h3>{`0.1 Course Setup and Introductions`}</h3>
    <p>{`Introduce yourself to the class.`}</p>
    <ul>
      <li parentName="ul">{`What is your major, year of study, and intended career after graduation?`}</li>
      <li parentName="ul">{`What exposure have you had to the field of “user experience?”`}</li>
    </ul>
    <p>{`As needed, share any questions or concerns you have as you get set up for this course.`}</p>
    <h3>{`0.2 Connecting`}</h3>
    <p>{`After watching the video "Connecting" provided in Lesson 0, share your reaction. Consider the following:`}</p>
    <ul>
      <li parentName="ul">{`What do you think about the role technology plays in our lives today?`}</li>
      <li parentName="ul">{`Where are we heading with technology?`}</li>
      <li parentName="ul">{`What makes for real connection? Does technology help or hinder this?`}</li>
      <li parentName="ul">{`What is a biblical view of technology?`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      